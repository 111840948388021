<template>
  <div class="box">
      <div class="columns">
        <div class="column">               
          <div class="container has-background-white">
              <input-component name="password" v-model="currentUserToken" label="Access Token" placeholder="*******" required left-icon="fa fa-lock" type="password"></input-component>
              <button v-on:click="login()"  v-on:keyup.enter="login()" type="submit" class="button is-primary is-fullwidth has-margin-top-25">
                  <icon-component icon="fas fa-sign-in-alt"></icon-component>
                  <span>Login</span>
              </button>
            </div>
            </div>
          </div>
        </div>
</template>

<script>
export default {
  name: 'LoginBox',
  async mounted() {
  
  },
  methods:{
    async login() {
      var foundToken = this.$store.getters.getUserTokens.map((item)=>item["password"]).indexOf(this.currentUserToken);
      if(foundToken >=0) {
        localStorage.setItem('user-token', JSON.stringify(this.$store.getters.getUserTokens[foundToken]))
        window.location.href = "/";
      } else {
        this.$notify({
          group: 'default',
          type:"error",
          title: 'Wrong access token',
          text:"check token and try again",
          duration:5000
        });
      }
    }
  },
  computed:{
    
  },
  data() {
    return {
      isLoading:true,
      currentUserToken:""
    }
  }
}
</script>

<style scoped>
  .home {
    padding: 25px;
  }

  .message {
    margin-top: 50px;
  }

  .epack-logo {
    background-image: url("@/assets/logo-seal.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}



.loggedInScreen {
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100px;
    width: 100%;
    background:white;
    opacity: 0.9;
    z-index: 100;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

</style>
