<template>
  <div class="home">
    <div class="row">
      <div class="column">
        <DeviceBox></DeviceBox>
      </div>
    </div>
  </div>
</template>

<script>

import DeviceBox from "@/components/DeviceBox.vue"
export default {
  name: 'HomeView',
  components:{
    DeviceBox
  }

}
</script>
<style>


</style>