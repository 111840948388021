<template>
    <div class="columns" :class="!!$slots['optional-controls'] ? optional_controls_class : ''" :style="!!$slots['optional-controls'] ? optional_controls_style : ''">
        <modal-component v-if="exportSettings.modal" @close="exportSettings.modal = false">
            <div class="box">
                <label class="label">{{$trans('Eksportuj:')}}</label>
                <input-component type="radio" v-model="exportSettings.mode" :options="[
                    {text:'wszystkie rekordy z bieżącej strony',value:0},
                    {text:'wybrane rekordy z bieżącej strony',value:1},
                    {text:'wszystkie dostępne rekordy',value:2}
                ]"></input-component>
                <input-component type="checkbox" :label="$trans('Dołącz nazwy kolumn')" v-model="exportSettings.prependHeaders"></input-component>
                <hr>
                <table class="table is-fullwidth" v-if="exportSettings.mode == 1 && exportSettings.rowsSelect.length">
                    <thead>
                        <tr>
                            <th>
                                {{$trans('Eksportuj')}}
                            </th>
                            <th v-for="(column, i) in exportable_columns" :key="i">
                                {{column.label}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, i) in exportSettings.rowsSelect" :key="i">
                            <td style="text-align: center;" :data-label="$trans('Eksportuj')">
                                <input-component type="checkbox" labelClass="mr-0 pr-0" label=" " v-model="row.selected"></input-component>
                            </td>
                            <td v-for="(cell, j) in row.row" :key="j" :data-label="exportable_columns[j].label">
                                <div>
                                    {{cell}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="notification is-info is-light" style="padding: 0.5rem 1rem 0.5rem 1rem; margin-bottom: 1rem;">
                    {{$trans('Rekordów wybranych do eksportu: %(exportCount)s',{exportCount})}}
                </div>
                <div class="columns">
                    <div class="column is-narrow">
                        <button class="button is-danger is-fullwidth" @click="exportSettings.modal = false">
                            {{$trans('Anuluj')}}
                        </button>
                    </div>
                    <div class="column">
                        <div class="field has-addons">
                            <p class="control" style="width: 100%">
                                <button class="button is-success is-fullwidth is-outlined" @click="executeExport('export.csv')" :disabled="exportCount === 0">
                                    <icon-component icon="fas fa-file-csv"></icon-component>
                                    <span>{{$trans('Pobierz CSV')}}</span>
                                </button>
                            </p>
                            <p class="control" style="width: 100%">
                                <button class="button is-success is-fullwidth" @click="executeExport('export.xlsx')" :disabled="exportCount === 0">
                                    <icon-component icon="fas fa-file-excel"></icon-component>
                                    <span>{{$trans('Pobierz XLSX')}}</span>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </modal-component>
        <modal-component v-if="exportSettings.modal && (typeof exportSettings.progress) !== 'undefined'">
            <div class="box">
                <h1 class="title">
                    {{$trans('Proszę czekać, trwa eksportowanie danych...')}}
                </h1>
                <progress class="progress is-success" :value="exportSettings.progress" :max="exportCount"></progress>
            </div>
        </modal-component>
        <div class="column is-narrow is-optional-controls" :class="{'has-search': display_search}" v-if="!!$slots['optional-controls']">
            <div class="control field has-addons is-search" v-if="display_search">
                <div class="control is-expanded">
                    <input v-model="temporary_search" v-on:keyup.enter="applySearch" class="input" type="text" :placeholder="$trans('Szukaj...')">
                </div>
                <div class="control">
                    <a class="button is-info" @click="applySearch">
                        <icon-component icon="fas fa-search"/>
                    </a>
                </div>
            </div>
            <button class="button has-icons-right is-info is-outlined is-fullwidth" :class="loader?'is-loading':''" v-if="display_refresh" @click="forceUpdate">
                <span>{{$trans('Odśwież')}}</span>
                <icon-component icon="fas fa-sync-alt" class="is-right"></icon-component>
            </button>
            <button class="button has-icons-right is-link is-outlined is-fullwidth" :class="loader?'is-loading':''" v-if="exportable" @click="startExport" :disabled="data_count === 0">
                <span>{{$trans('Pobierz')}}</span>
                <icon-component icon="fas fa-file-download" class="is-right"></icon-component>
            </button>
            <div class="field is-hidden-desktop" v-if="sortable_columns.length">
                <label class="label">{{$trans('Sortowanie:')}}</label>
                <p class="control select is-fullwidth">
                    <select v-model="sort_select">
                        <option v-for="(col,i) in sortable_columns" :key="i" :value="col.value">{{col.text}}</option>
                    </select>
                </p>
            </div>
            <slot name="optional-controls"></slot>
        </div>
        <div class="column">
            <div class="columns is-multiline" v-if="(display_search || display_refresh || exportable || sortable_columns.length) && !$slots['optional-controls']">
                <div class="column is-narrow" v-if="display_search">
                    <div class="control field has-addons">
                        <div class="control is-expanded">
                            <input v-model="temporary_search" v-on:keyup.enter="applySearch" class="input" type="text" :placeholder="$trans('Szukaj...')">
                        </div>
                        <div class="control">
                            <a class="button is-info" @click="applySearch">
                                <icon-component icon="fas fa-search"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column is-narrow" v-if="display_refresh">
                    <button class="button has-icons-right is-info is-outlined is-fullwidth" :class="loader?'is-loading':''" @click="forceUpdate">
                        <span>{{$trans('Odśwież')}}</span>
                        <icon-component icon="fas fa-sync-alt" class="is-right"></icon-component>
                    </button>
                </div>
                <div class="column is-narrow" v-if="exportable">
                    <button class="button has-icons-right is-link is-outlined is-fullwidth" :class="loader?'is-loading':''" @click="startExport" :disabled="data_count === 0">
                        <span>{{$trans('Pobierz')}}</span>
                        <icon-component icon="fas fa-file-download" class="is-right"></icon-component>
                    </button>
                </div>
                <div class="column is-narrow is-hidden-desktop" v-if="sortable_columns.length">
                    <label class="label">{{$trans('Sortowanie:')}}</label>
                    <p class="control select is-fullwidth">
                        <select v-model="sort_select">
                            <option v-for="(col,i) in sortable_columns" :key="i" :value="col.value">{{col.text}}</option>
                        </select>
                    </p>
                </div>
            </div>
            <div class="table-container">
                <table class="table is-table-component is-fullwidth" v-bind:class="{'is-striped':striped}" style="position: relative;">
                    <loader-component :show="loader"></loader-component>
                    <thead ref="thead">
                        <tr>
                            <th v-for="({column, colspan}, i) in visible_columns_thead" :key="i" :colspan="colspan" v-bind:class="[{'sort-asc':current_sort==column.sortField && current_sort_dir==1,'sort-desc':current_sort==column.sortField && current_sort_dir==-1,'sortable':column.sortable}, column.class]" v-on:click="setSort(column)"> 
                                <icon-component v-if="column.sortable" :icon="((current_sort==column.sortField && current_sort_dir==1)?'fas fa-sort-amount-down-alt':((current_sort==column.sortField && current_sort_dir==-1)?'fas fa-sort-amount-down':'fas fa-sort'))"/>
                                <span v-html="column.label"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row,ri) in visible_rows" :key="ri" 
                        v-on:click="()=>{if(visible_rows_clickable[ri]) $emit('row-click',row)}" 
                        v-bind:class="[row_class_generator(row),typeof visible_rows_links[ri] !== 'undefined'?'row-link':'',visible_rows_clickable[ri]?'clickable-row':'']" 
                        v-on:mousedown="()=>{if(visible_rows_clickable[ri]) {$emit('row-mousedown',row)}}" 
                        v-on:mouseup="()=>{if(visible_rows_clickable[ri]) {$emit('row-mouseup',row)}}" 
                        v-on:mousemove="()=>{if(visible_rows_clickable[ri]) $emit('row-mousemove',row)}" 
                        >
                            <td v-for="(column,ci) in visible_columns" :key="ci" v-bind:class="column.class" :data-label="`${current_sort==column.sortField?(current_sort_dir==1?'▲ ':'▼ '):''}${column.label}`">
                                <component :is="typeof visible_rows_links[ri] !== 'undefined'?(vue_router_links?'router-link':'a'):'div'" v-bind="typeof visible_rows_links[ri] !== 'undefined'?(vue_router_links?{to:visible_rows_links[ri]}:{href:visible_rows_links[ri]}):{}">
                                    <!-- function based cell rendering -->
                                    <div v-if="typeof column.renderFn === 'function'" v-html="column.renderFn(row, {row_index:ri, column:column, column_index:ci, columns:normalised_columns, query:current_details})"></div>
                                    <!-- component based cell rendering -->
                                    <component v-else-if="column.component" :is="column.component.name" v-bind="typeof column.component.props === 'object'?column.component.props:(typeof column.component.props === 'function'?column.component.props({row:row, row_index:ri, column:column, column_index:ci, columns:normalised_columns, query:current_details}):{})"></component>
                                    <!-- slot based cell rendering -->
                                    <slot v-else-if="(typeof column.slotName) !== 'undefined'" :name="column.slotName" v-bind:row="row" v-bind:row_index="ri" v-bind:column="column" v-bind:column_index="ci" v-bind:columns="normalised_columns" v-bind:query="current_details"></slot>
                                    <!-- translated field -->
                                    <template v-else-if="column.translate">
                                        <!-- nested data view -->
                                        <template v-if="typeof column.field === 'string' && column.field.indexOf('__')!==-1">
                                            {{ $trans(column.field.split('__').reduce((a,e)=>getIfIsset(()=>a[e]),row)) }}
                                        </template>
                                        <!-- default data view -->
                                        <template v-else>
                                            {{ $trans(column.field === -1 ? row : row[column.field]) }}
                                        </template>
                                    </template>
                                    <!-- field not translated -->
                                    <template v-else>
                                        <!-- nested data view -->
                                        <template v-if="typeof column.field === 'string' && column.field.indexOf('__')!==-1">
                                            {{ column.field.split('__').reduce((a,e)=>getIfIsset(()=>a[e]),row) }}
                                        </template>
                                        <!-- default data view -->
                                        <template v-else>
                                            {{ column.field === -1 ? row : row[column.field] }}
                                        </template>
                                    </template>
                                </component>
                            </td>
                        </tr>
                        <tr v-if="visible_rows.length == 0 && !loader" class="no-results">
                            <td :colspan="visible_columns.length">
                                <slot v-if="fetch_error !== false" name="error-ocurred">
                                    <div class="notification" v-if="fetch_error === 403">
                                        <icon-component icon="fas fa-user-lock" size="L" class="is-centered"/><br>
                                        {{$trans('Nie masz uprawnień do tej akcji!')}}
                                    </div>
                                    <div class="notification" v-else>
                                        <icon-component icon="fas fa-exclamation-triangle" size="L" class="is-centered"/><br>
                                        {{$trans('Wystąpił błąd podczas pobierania danych, spróbuj ponownie.')}}
                                    </div>
                                </slot>
                                <slot v-else-if="current_search!=''" name="empty-search">
                                    <div class="notification">
                                        <icon-component icon="fas fa-search" size="L" class="is-centered"/><br>
                                        {{$trans('Twoje wyszukiwanie nie zwróciło żadnych wyników.')}}
                                    </div>
                                </slot>
                                <slot v-else name="no-data">
                                    <div class="notification">
                                        {{$trans('Nie znaleźliśmy żadnych danych do wyświetlenia.')}}
                                    </div>
                                </slot>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="pagination_list.length>1 || pagination_informations" class="columns">
                <div class="column is-one-third is-offset-one-third is-centered" v-if="pagination_list.length>1">
                    <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                        <a class="pagination-previous" :disabled="!prev_page_active" v-on:click="()=>{if(prev_page_active)paginate(current_page-1)}">{{$trans('Poprzedni')}}</a>
                        <a class="pagination-next" :disabled="!next_page_active" v-on:click="()=>{if(next_page_active)paginate(current_page+1)}">{{$trans('Następny')}}</a>
                        <ul class="pagination-list is-hidden-touch">
                            <li v-for="(p,i) in pagination_list" :key="i">
                                <span v-if="typeof p === 'undefined'" class="pagination-ellipsis">&hellip;</span>
                                <a v-on:click="paginate(p)" v-else class="pagination-link" v-bind:class="{'is-current':current_page==p}">{{p}}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div v-if="pagination_informations" class="column is-one-third" v-bind:class="pagination_list.length>1?'':'is-offset-two-thirds'">
                    <div class="field is-grouped is-grouped-right" style="line-height: 1.75rem;">
                        <span>
                            {{$trans('rekordy %(start)s-%(stop)s z %(total)s',{start: current_start, stop: current_stop, total: data_count})}}
                            <template v-if="Array.isArray(pagination_size_list) && pagination_size_list.length>1">
                                ,
                                <p class="control select is-small">
                                    <select v-model="current_pagination_size">
                                        <option v-for="(cnt,i) in pagination_size_list" :key="i">{{cnt}}</option>
                                    </select>
                                </p>
                                {{$trans('rekordów na stronę')}}
                            </template>
                            <template v-else>
                                , {{current_pagination_size}} {{$trans('rekordów na stronę')}}
                            </template>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import inputComponent from './input-component.vue';
import loaderComponent from './loader-component.vue';
import modalComponent from './modal-component.vue';

export default {
    props:{
        //required prop
        columns:{
            //Array of columns definitions (objects structure below)
            type:Array,
            default:()=>[]
            /* 
            column definition structure (with defaults)
            {
                sortable:       this.sortable,
                searchable:     this.searchable,    //this field is used only for static data mode
                hidden:         false,
                label:          column.field,       //defaults to this columns "field" value if one was given, or empty string ("") if not
                class:          undefined,
                colspan:        [0,0],              //cells to merge in thead
                translate:      false,              //this fields value is translated before displaying (applies only to field based cell rendering)
                //at least one of those four fields below has to be defined, if more than one is present they are check in order renderFn->component->field
                field:          undefined,
                renderFn:       undefined,
                slotName:       undefined,          //slot to render for this cell, will be passed object ({row:row, row_index:ri, column:column, column_index:ci, columns:normalised_columns}) via props
                    component:  undefined,
                    OR
                    component:{
                        name:   'COMPONENT NAME',   //no default value over here
                        props:  ()=>({})            //function that will be called with object ({row:row, row_index:ri, column:column, column_index:ci, columns:normalised_columns}) and should return object with props to bind to given component
                        OR
                        props:  {}                  //when props is an object, those object props are passed to component
                        OR
                        props:  undefined           //if props is undefined (or is not defined) no props are passed to component
                    }
            }
            */
        },

        //either url or data has to be provided
        url:{
            //url to AJAX ask for data to display (instead of static Array based data), if both are given static data is preferred
            type:String,
            default:undefined
        },

        additional_url_params:{
            //additional url params that will be merged with pagination/sorting/filtering ones
            type:Object,
            default:()=>({})
        },

        data:{
            //Array of static data to display (instead of AJAX based data), if both are given static data is preferred
            type:Array,
            default:undefined
        },

        //optional props
        pagination_size_list:{
            //array of pagination size elements, or single number (in an array, or not) if no choice is given to user and pagination size is fixed to that number
            type:[Array,Number],
            default:()=>[10,30,50,100]
        },

        pagination_default_size:{
            //default size of pagination, if none is given defaults to first (or only) element from pagination_size_list
            type:Number,
            default:-1
        },

        searchable:{
            //determines if whole table is searchable (has search input)
            type:Boolean,
            default:true
        },

        sortable:{
            //determines if table columns are sortable by default or not
            type:Boolean,
            default:true
        },

        row_class_generator:{
            //function that is called with each row object and returns this row CSS class name(s)
            type:Function,
            default:()=>''
        },

        row_link_generator:{
            //function that is called with each row object and returns this row link href url, or boolean false if this functionality is not used
            type:[Function,Boolean],
            default:false,
            validate(val){
                if(typeof val === 'boolean') return val === false;
                return typeof val === 'function';
            }
        },

        vue_router_links:{
            //if generated row links should use vue router <router-link> instead of <a>
            type:Boolean,
            default:false
        },

        striped:{
            //if the table is striped (pobably to be turned off when using row_class_generator)
            type:Boolean,
            default:true
        },

        url_params:{
            //if changes in this table (pagination/sorting/filtering) should result in url changes
            type:Boolean,
            default:true
        },

        clickable_rows:{
            //if rows of this table should be clickable
            type:[Function,Boolean],
            default:true
        },

        initial_sort:{
            //default sort in none is given in url
            type:String,
            default:undefined
        },

        refreshable:{
            //refresh button for table
            type:Boolean,
            default:true
        },

        pagination_informations:{
            //if pagination information should be visible on the bottom of the table
            type:Boolean,
            default:true
        },

        auto_refresh_name:{
            type:[String, Boolean],
            default: false
        },

        auto_refresh_endpoint:{
            type:String,
            default:'/refresher'
        },

        optional_controls_class:{
            type:String,
            default:''
        },

        optional_controls_style:{
            type:String,
            default:''
        },

        exportable:{
            type:Boolean,
            default:false
        }
    },
    components:{
        loaderComponent,
        modalComponent,
        inputComponent
    },
    mounted() {
        if(this.columns.length == 0)
            throw new Error("'columns' needs to be provided for table to work!");
        if(typeof this.url === 'undefined' && typeof this.data === 'undefined')
            throw new Error("One of 'url' or 'data' has to be provided for table to work!");
        
        this.current_pagination_size = this.real_pagination_default_size;
        
        if(this.url_params){
            window.onpopstate = this.popState;
            this.popState();
        }
        else{
            this.current_sort = this.real_sort_default.sort;
            this.current_sort_dir = this.real_sort_default.dir;
        }
        this.loader = false;
        this.ready = true;

        if(this.auto_refresh_name !== false){
            if(typeof(WebSocket) !== "undefined"){
                var loc = window.location, new_uri;
                if (loc.protocol === "https:") {
                    new_uri = "wss:";
                } else {
                    new_uri = "ws:";
                }
                new_uri += "//" + loc.host + this.auto_refresh_endpoint;
                new WebSocket(new_uri).onmessage = (e) => {
                    const data = JSON.parse(e.data);
                    if(Object.keys(data).includes(this.auto_refresh_name)){
                        this.forceUpdate();
                    }
                };
            }
            else{
                console.warn("Auto refresh is not supported by your browser!")
            }
        }
    },
    asyncComputed:{
        visible_rows:{
            get: async function(){
                //dummy workaround to make forceUpdate() work
                this.loader = this.force_update_ctr !== '';
                this.fetch_error = false;
                var data
                try{
                    data = await this.getData();
                    if(this.scrollToTop){
                        if(isset(()=>scrollIntoView) && isset(()=>this.$refs.thead)){
                            scrollIntoView(this.$refs.thead, {
                                scrollMode: 'if-needed',
                                block: 'nearest'
                            });
                        }
                        this.scrollToTop = false;
                    }
                    this.data_count = data.data_count;
                    this.pages_count = data.pages_count;
                    if(!this.initialLoadCompleted && this.ready && !Array.isArray(data)){
                        this.initialLoadCompleted = true;
                    }
                }
                catch(error){
                    console.error(error);
                    if(isset(()=>error.response.status)){
                        this.fetch_error = error.response.status;
                    }
                    else{
                        this.fetch_error = true
                    }
                    data = {
                        data: []
                    };
                }
                this.loader = false;
                return data.data;
            },
            default: [],
        }
    },
    computed:{
        exportCount(){
            switch(parseInt(this.exportSettings.mode)){
                case 0: return this.data_count === 0 ? 0 : this.current_stop-this.current_start+1
                case 1: return this.exportSettings.rowsSelect.filter((row)=>row.selected).length
                case 2: return this.data_count
            }
            return 0
        },

        visible_rows_links:function(){
            const ret = new Array(this.visible_rows.length).fill(undefined);
            if(this.visible_rows.length == 0 || this.row_link_generator === false){
                return ret;
            }
            for(const i in this.visible_rows){
                ret[i] = this.row_link_generator(this.visible_rows[i]);
            }
            return ret;
        },

        visible_rows_clickable:function(){
            const ret = new Array(this.visible_rows.length).fill(false);
            if(this.visible_rows.length == 0){
                return ret;
            }
            if(typeof this.clickable_rows === 'boolean'){
                return ret.fill(this.clickable_rows);
            }
            return ret.map((e,i)=>this.clickable_rows(this.visible_rows[i]));
        },

        normalised_columns:function(){
            var getWithFallback = function(element,prop,fallback){
                return isset(()=>element[prop])?element[prop]:fallback;
            }
            return this.columns.map((c)=>{
                if(!isset(()=>c.field) && !istype(()=>c.renderFn,'function') && !istype(()=>c.component,['string','object']) && !istype(()=>c.slotName,'string'))
                    throw new Error("Each column should have one of >>field<< or >>renderFn<< or >>component<< or >>slotName<<!");
                var normalised_column = {
                    sortable:           getWithFallback(c,  'sortable'  ,this.sortable),
                    searchable:         getWithFallback(c,  'searchable',this.searchable),
                    hidden:             getWithFallback(c,  'hidden'    ,false),
                    field:              getWithFallback(c,  'field'     ,undefined),
                    sortField:          getWithFallback(c,  'sortField' ,c.field),
                    label:              getWithFallback(c,  'label'     ,c.field),
                    renderFn:           getWithFallback(c,  'renderFn'  ,undefined),
                    exportFn:           getWithFallback(c,  'exportFn'  ,undefined),
                    component:          getWithFallback(c,  'component' ,undefined),
                    slotName:           getWithFallback(c,  'slotName'  ,undefined),
                    class:              getWithFallback(c,  'class'     ,undefined),
                    translate:          getWithFallback(c,  'translate' ,false),
                    colspan:            getWithFallback(c,  'colspan'   ,[0,0]),
                };
                if(!isset(()=>normalised_column.field)){
                    if(!isset(()=>normalised_column.sortField)){
                        normalised_column.sortable = false;
                    }
                    normalised_column.searchable = false;
                }
                if(!isset(()=>normalised_column.label)){
                    normalised_column.label = "";
                }
                if(isset(()=>normalised_column.component)){
                    if(!(istype(()=>normalised_column.component.name,'string') && istype(()=>normalised_column.component.props,['function','object','undefined']))){
                        throw new Error("Column component definition is wrong!");
                    }
                }
                if(!normalised_column.sortable){
                    normalised_column.sortField = undefined;
                }
                if(!(Array.isArray(normalised_column.colspan) && normalised_column.colspan.length === 2 && Number.isInteger(normalised_column.colspan[0]) && Number.isInteger(normalised_column.colspan[1]) && normalised_column.colspan[0] <= 0 && 0 <= normalised_column.colspan[1])){
                    throw new Error("Colspan definition is wrong!");
                }
                return normalised_column;
            });
        },

        visible_columns:function(){
            return this.normalised_columns.filter((c)=>!c.hidden);
        },

        exportable_columns:function(){
            return this.visible_columns.filter((col)=>typeof col.field !== 'undefined' || typeof col.renderFn === 'function' || typeof col.exportFn === 'function')
        },

        visible_columns_thead:function(){
            var ret = [];
            var skipNext = 0;
            for(let index = 0; index < this.visible_columns.length; index++){
                if(skipNext>0){
                    skipNext -= 1;
                }
                else{
                    const column = this.visible_columns[index];
                    if(column.colspan[0] < 0){
                        if(Math.abs(column.colspan[0]) > ret.length){
                            throw new Error("Colspan definition is wrong!");
                        }
                        ret.splice(ret.length - 1 - Math.abs(column.colspan[0]), Math.abs(column.colspan[0]));
                    }
                    if(column.colspan[1] > 0){
                        skipNext = column.colspan[1];
                    }
                    ret.push({
                        column,
                        colspan: Math.abs(column.colspan[0])+column.colspan[1]+1
                    });
                }
            }
            return ret;
        },

        sortable_columns:function(){
            const sortableCols = this.normalised_columns.filter((c)=>c.sortable)
            if(!this.sortable || sortableCols.length === 0){
                return []
            }
            else{
                let columns = [{
                    value: '',
                    text: $trans('domyślne')
                }]
                for(const column of sortableCols){
                    columns.push({
                        value: column.sortField,
                        text: `${column.label} ${$trans('rosnąco')}`
                    })
                    columns.push({
                        value: '-'+column.sortField,
                        text: `${column.label} ${$trans('malejąco')}`
                    })
                }
                return columns
            }
        },

        searchable_columns_fields:function(){
            return this.normalised_columns.filter((c)=>c.searchable).map((c)=>c.field);
        },

        next_page_active:function(){
            return this.current_page != this.pages_count;
        },

        prev_page_active:function(){
            return this.current_page != 1;
        },

        pagination_list:function(){
            var current = this.current_page,
                last = this.pages_count,
                delta = 1,
                left = current - delta,
                right = current + delta + 1,
                range = [],
                rangeWithDots = [],
                l;

            for (let i = 1; i <= last; i++) {
                if (i == 1 || i == last || i >= left && i < right) {
                    range.push(i);
                }
            }

            for (let i of range) {
                if (l) {
                    if (i - l === 2) {
                        rangeWithDots.push(l + 1);
                    } else if (i - l !== 1) {
                        rangeWithDots.push(undefined);
                    }
                }
                rangeWithDots.push(i);
                l = i;
            }

            return rangeWithDots;
        },

        real_pagination_default_size:function(){
            if(this.pagination_default_size !== -1)
                return this.pagination_default_size;
            if(Array.isArray(this.pagination_size_list)){
                if(this.pagination_size_list.length)
                    return this.pagination_size_list[0];
                throw new Error("Pagination size list was given an empty Array!");
            }
            if(Number.isInteger(this.pagination_size_list))
                return this.pagination_size_list;
            throw new Error("Default pagination size missing!");
        },

        real_sort_default:function(){
            if(typeof this.initial_sort === 'undefined'){
                return {
                    sort: '',
                    dir: 1
                };
            }
            else{
                if(this.initial_sort.startsWith('-')){
                    return {
                        sort: this.initial_sort.substring(1),
                        dir: -1
                    };
                }
                else{
                    return {
                        sort: this.initial_sort,
                        dir: 1
                    };
                }
            }
        },

        current_query:function(){
            return this.buildQuery();
        },

        current_details:function(){
            let query = JSON.parse(JSON.stringify(this.current_query));
            if(!window.isset(()=>query.limit)){
                query.limit = 10;
            }
            if(!window.isset(()=>query.offset)){
                query.offset = 0;
            }
            query.current_start = this.current_start;
            query.current_stop = this.current_stop;
            return query;
        },

        current_start:function(){
            if(this.data_count === 0){
                return 0;
            }
            return (this.current_page-1)*this.current_pagination_size+1;
        },

        current_stop:function(){
            return Math.min(this.current_start + this.current_pagination_size - 1, this.data_count);
        },

        display_search:function(){
            return this.searchable && !!this.searchable_columns_fields.length;
        },

        display_refresh:function(){
            return this.refreshable && typeof this.url !== 'undefined';
        },

        computed_sort_select:function(){
            if(this.current_sort === ''){
                return ''
            }
            return `${this.current_sort_dir === -1 ? '-' : ''}${this.current_sort}`
        }
    },
    methods:{
        buildQuery(params={
            current_pagination_size:    this.current_pagination_size,
            current_page:               this.current_page,
        }){
            var ret = {...this.current_additional_params};
            if(params.current_pagination_size != 10)
                ret.limit = params.current_pagination_size;
            if(params.current_page != 1)
                ret.offset = (params.current_page-1)*params.current_pagination_size+1;
            if(this.current_sort != ''){
                ret.sort = this.current_sort;
                ret.dir = this.current_sort_dir;
            }
            if(this.current_search != "")
                ret.filter = this.current_search;
            return ret;
        },

        startExport(){
            this.exportSettings.modal = true
            this.exportSettings.mode = 0
            this.exportSettings.prependHeaders = true
            this.exportSettings.rowsSelect = this.visible_rows.map(this.parseRowForExport).map((row)=>({row, selected:true}))
            this.exportSettings.progress = undefined
        },

        async executeExport(filename='export.xlsx'){
            let exportData = []
            if(this.exportSettings.mode == 0){
                exportData = this.visible_rows
            }
            else if(this.exportSettings.mode == 1){
                exportData = this.exportSettings.rowsSelect.filter((row)=>row.selected).map((row)=>row.row)
            }
            else if(this.exportSettings.mode == 2){
                if(typeof this.url !== 'undefined'){
                    //data from backend
                    if(this.exportCount > this.visible_rows.length){
                        let ret = await Vue.dialog.confirm($trans('Czy jesteś pewien że chcesz wyeksportować %(exportCount)s rekordów?',{exportCount: this.exportCount}),{
                            okText:         $trans('Tak'),
                            cancelText:     $trans('Nie')
                        });
                        if(!ret.success){
                            return
                        }
                    }
                }
                const querySize = 100
                let page = 1
                while(exportData.length < this.exportCount){
                    this.exportSettings.progress = exportData.length
                    exportData = exportData.concat((await this.getData({
                        current_pagination_size: querySize,
                        current_page: page++
                    })).data)
                }
            }
            if(this.exportSettings.mode != 1){
                exportData = exportData.map(this.parseRowForExport)
            }
            if(this.exportSettings.prependHeaders){
                exportData.unshift(this.exportable_columns.map((column)=>column.label))
            }
            
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(exportData), 'data')
            XLSX.writeFile(wb, filename)
            
            this.exportSettings.modal = false
        },

        parseRowForExport(row){
            return this.exportable_columns.map((column)=>{
                if(typeof column.exportFn === 'function'){
                    try{
                        return column.exportFn(row)
                    }
                    catch(e){
                        return undefined
                    }
                }
                if(typeof column.field !== 'undefined'){
                    let value
                    if(typeof column.field === 'string' && column.field.indexOf('__')!==-1){
                        value = column.field.split('__').reduce((a,e)=>getIfIsset(()=>a[e]),row)                        
                    }
                    else{
                        value = row[column.field]
                    }
                    if(column.translate){
                        value = $trans(value)
                    }
                    return value
                }
                if(typeof column.renderFn === 'function'){
                    try{
                        return column.renderFn(row)
                    }
                    catch(e){
                        return undefined
                    }
                }
                return undefined
            })
        },

        async getData(params={
            current_pagination_size: this.current_pagination_size,
            current_page: this.current_page
        }){
            if(!this.ready) return [];
            let ret = {
                data_count: undefined,
                pages_count: undefined,
                data: undefined
            }
            var data
            if(typeof this.data !== 'undefined'){
                //data obtained by prop
                data = this.data.slice();
                if(this.current_search!="" && !!this.searchable_columns_fields.length){
                    data = data.filter((e)=>{
                        for(const column of this.searchable_columns_fields){
                            if(String(e[column]).toLowerCase().includes(this.current_search.toLowerCase()))
                                return true;
                        }
                        return false;
                    });
                }
                ret.data_count = data.length;
                ret.pages_count = Math.ceil(data.length/params.current_pagination_size);
                if(this.current_sort!=''){
                    const getField = (object)=>{
                        return this.current_sort.includes('__')?this.current_sort.split('__').reduce((a,e)=>getIfIsset(()=>a[e]),object):object[this.current_sort];
                    }
                    data = data.sort((a,b)=>{
                        const aField = getField(a);
                        const bField = getField(b);
                        if(aField == bField)
                            return 0;
                        if(this.current_sort_dir == 1)
                            return aField < bField ? -1 : 1;
                        return aField > bField ? -1 : 1;
                    });
                }
                var start = (params.current_page-1) * params.current_pagination_size;
                ret.data = data.slice(start, start + params.current_pagination_size);
                return ret;
            }
            else if(typeof this.url !== 'undefined'){
                //data obtained by axios
                data = (await this.axios.get(this.url+(!this.url.includes('?')?'?':'&')+Object.entries(this.buildQuery(params)).map(([key,val])=>`${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&'))).data;
                this.$emit('server-data', data)
                ret.data_count = data.count;
                ret.pages_count = Math.ceil(ret.data_count/params.current_pagination_size);
                ret.data = data.data;
                return ret;
            }
            throw new Error("One of 'url' or 'data' has to be provided for table to work!");
        },

        setSort(column) {
            if(!column.sortable)
                return;
            column = column.sortField;
            if(this.current_sort==column){
                if(this.current_sort_dir == 1){
                    this.current_sort_dir = -1;
                }
                else{
                    this.current_sort = '';
                }
            }
            else{
                this.current_sort = column;
                this.current_sort_dir = 1;
            }
        },

        paginate(page){
            let initialPage = parseInt(this.current_page);
            this.current_page = parseInt(page);
            this.guardCurrentPage();
            if(this.initialLoadCompleted && initialPage !== this.current_page){
                this.scrollToTop = true;
            }
        },

        applySearch(){
            this.current_search = this.temporary_search;
        },

        changeUrl(){
            if(!this.url_params) return;
            if(this.preventBrowserUrlChange){
                this.preventBrowserUrlChange = false;
                return;
            }
            history.pushState(null, "", '?'+Object.entries(this.current_query).map(([key,val])=>`${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&'));
        },

        popState(state){
            state = window.location.search?window.location.search.substring(1).split('&').reduce((o,e)=>{e=e.split('='); o[decodeURIComponent(e[0])] = decodeURIComponent(e[1]); return o;},{}):{};
            //defaults
            if(!isset(()=>state.limit))
                state.limit = this.real_pagination_default_size;
            else{
                state.limit = parseInt(state.limit);
                //todo - validate against allowed limit's
            }
            if(!isset(()=>state.offset))
                state.offset = 0;
            else
                state.offset = parseInt(state.offset);
            if(!isset(()=>state.filter))
                state.filter = "";
            if(!isset(()=>state.sort) || !isset(()=>state.dir)){
                state.sort = this.real_sort_default.sort;
                state.dir = this.real_sort_default.dir;
            }
            else
                state.dir = parseInt(state.dir);
            
            if(this.current_pagination_size != state.limit){
                this.preventBrowserUrlChange = true;
                this.current_pagination_size = state.limit;
            }
            
            var state_page = state.offset==0?1:Math.floor((state.offset-1)/state.limit)+1;
            if(this.current_page != state_page){
                this.preventBrowserUrlChange = true;
                this.current_page = state_page;
            }

            if(this.current_sort != state.sort){
                this.preventBrowserUrlChange = true;
                this.current_sort = state.sort;
            }
            
            if(this.current_sort_dir != state.dir){
                this.preventBrowserUrlChange = true;
                this.current_sort_dir = state.dir;
            }

            if(this.current_search != state.filter){
                this.preventBrowserUrlChange = true;
                this.current_search = state.filter;
            }

            const localKeys = ['limit','offset','filter','sort','dir'];
            var thisAdditional = Object.keys(state).filter(key => !localKeys.includes(key)).reduce((o,k)=>{
                o[k] = state[k];
                return o;
            },{});
            if(!objectsEqual(this.current_additional_params, thisAdditional)){
                this.preventBrowserUrlChange = true;
                this.current_additional_params = thisAdditional;
                this.$emit('additional-changed', thisAdditional);
            }
        },

        guardCurrentPage(){
            if(this.current_page>this.pages_count)
                this.current_page = this.pages_count;
            if(this.current_page<1)
                this.current_page = 1;
        },

        getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    // Does this cookie string begin with the name we want?
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        },

        forceUpdate() {
            this.force_update_ctr++;
        },

        clearQuery() {
            this.current_sort = '';
            this.current_sort_dir = 1;
            this.current_search = '';
            this.current_page = 1;
            this.current_pagination_size = this.real_pagination_default_size;
            this.current_additional_params = {};
            this.$emit('additional-changed', {});
        },

        clearSearch(){
            this.current_search = ''
        },

        search(term){
            if(this.searchable){
                this.temporary_search = term;
                this.applySearch();
            }
        }
    },
    data() {
        return {
            loader:                     true,
            current_sort:               '',
            current_sort_dir:           1,
            sort_select:                '',
            current_search:             '',
            current_additional_params:  {},
            temporary_search:           '',
            current_pagination_size:    1,
            current_page:               1,
            pages_count:                1,
            data_count:                 0,
            debounceUrlChanges:         debounce(this.changeUrl,100),
            preventBrowserUrlChange:    true,
            ready:                      false,
            fetch_error:                false,
            force_update_ctr:           0,
            exportSettings:{
                modal:                  false,
                mode:                   0,
                prependHeaders:         true,
                rowsSelect:             undefined,
                progress:               undefined,
            },
            scrollToTop:                false,
            initialLoadCompleted:       false
        }
    },
    watch:{   
        current_query:{
            deep: true,
            handler(newVal,oldVal){
                if(!objectsEqual(newVal,oldVal)){
                    this.debounceUrlChanges();
                }
            }
        },
        
        current_search:function(){
            this.temporary_search = this.current_search;
        },

        pages_count:function(){
            this.guardCurrentPage();
        },

        additional_url_params:{
            deep: true,
            immediate: true,
            handler(){
                if(!objectsEqual(this.current_additional_params, this.additional_url_params)){
                    this.current_additional_params = {...this.additional_url_params};
                }
            }
        },

        current_pagination_size:function(){
            if(typeof this.current_pagination_size == 'string'){
                this.current_pagination_size = parseInt(this.current_pagination_size);
            }
            else if(this.initialLoadCompleted){
                this.scrollToTop = true;
            }
        },

        sort_select:function(){
            let new_sort = undefined
            let new_dir = undefined
            if(this.sort_select === ''){
                new_sort = '';
            }
            else{
                if(this.sort_select.startsWith('-')){
                    new_dir = -1;
                    new_sort = this.sort_select.substring(1);
                }
                else{
                    new_dir = 1;
                    new_sort = this.sort_select;
                }
            }
            
            //prevent looping of sort change propagation
            if(new_sort !== this.current_sort){
                this.current_sort = new_sort;
            }
            if(typeof new_dir !== 'undefined'){
                if(new_dir !== this.current_sort_dir){
                    this.current_sort_dir = new_dir;
                }
            }
        },

        computed_sort_select:function(){
            if(this.computed_sort_select !== this.sort_select){
                this.sort_select = this.computed_sort_select
            }
        }
    }
}
</script>
<style lang="scss">
@import "~bulma/sass/utilities/functions.sass";
@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~bulma/sass/utilities/mixins.sass";

table.table.is-table-component {
    th {
        white-space: nowrap;
        &.sortable {
            cursor: pointer;
        }
        &.is-narrow span:last-child {
            white-space: break-spaces;
        }
    }

    & tr.clickable-row:not(.no-results) {
        cursor: pointer;
    }

    & tr.row-link td {
        padding: 0;
        position: relative;

        & > a {
            display: block;
            padding: 0.5em 0.75em;
            color: inherit;

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
            }
        }
    }

    & td, & th {
        vertical-align: middle;
        &.narrow {
            width: 1px;
            white-space: nowrap;
        }
    }

    td .icon:only-child {
        width: 100%;
    }
}

@include touch {
    table.table.is-table-component {
		display: block; 
        
        thead, tbody, th, td, tr { 
            display: block; 
        }

        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            border-top: 4px solid #888;

            &:not(:first-child){
                margin-top: 1em;
            }
        }

        td {
            position: relative;
            padding-left: 50%;

            &:last-child {
                border-bottom: none;
            }
        }

        tbody tr:last-child td {
            border-bottom-width: 1px;
        }

        tr.row-link > td > a, tr:not(.row-link) > td:not([data-label=""]) > div {
            padding-top: 2.5em !important;
        }
        
        td:before {
            position: absolute;
            top: 6px;
            left: 6px;
            white-space: nowrap;
            padding-right: 6px;
            font-weight: bold;
            content: attr(data-label);
        }

        td.is-narrow {
            white-space: initial !important;
            width: initial !important;
        }
    }
}
</style>