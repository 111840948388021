import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import store from './../store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(store);
  if(to.meta.requiresAuth) {
    try {
      var tokenData = JSON.parse(localStorage.getItem('user-token'));
      var currentUsersList = store.getters.getUserTokens;
      var foundUser = currentUsersList.map((item)=>item.password).indexOf(tokenData.password);
      if (!tokenData || foundUser < 0) {
        localStorage.removeItem('user-token');
        next('/login')
        this.$notify({
          group: 'default',
          type:"error",
          title: 'Wrong access token',
          text:"check token and try again",
          duration:5000
        });
      } 
      else next()
    } catch(error) {
      localStorage.removeItem('user-token');
        next('/login')
        this.$notify({
          group: 'default',
          type:"error",
          title: 'Wrong access token',
          text:"check token and try again",
          duration:5000
        });
    }
  } else next()
})

export default router
