<template>
  <div class="box">
      <modal-component v-if="modalActive">
        <div class="box">
             
              <div class="notification">
                SEAL ID: <b>{{ this.currentSeal.id }}</b>
              </div>
              <input-component name="name" v-model="currentSeal.name" label="Name" placeholder=""></input-component>
              <input-component name="latlng" v-model="currentSeal.location" label="Location" placeholder=""></input-component>
              <div class="notification is-info">
                To get the location go to <a href="https://maps.google.com/">Google Maps</a> right click on the map and select the coordinates. Paste them into the box above
              </div>
              <button v-on:click="saveCurrentData()" type="submit" class="button is-primary is-fullwidth has-margin-top-25">
                  <span>Save</span>
              </button>
              <br>
              <button v-on:click="modalActive=false" type="submit" class="button is-danger is-fullwidth has-margin-top-25">
                  <span>CLOSE</span>
              </button>

          
        </div>
      </modal-component>

      <div class="columns">
        <div class="column is-6">
          <div class="container has-background-white"> 
              <table-component v-on:row-click="rowClicked" :columns="deviceTableSettings.columns" :data="deviceTableData" :searchable="false">
                <template v-slot:indicator="{row}">
                  <status-indicator v-if="row.state=='armed'" status="positive" :pulse="true"></status-indicator>
                  <status-indicator v-if="row.state=='disarmed'" status="negative" :pulse="true"></status-indicator>
                  <status-indicator v-if="row.state=='inactive'"  :pulse="true"></status-indicator>
                  <status-indicator v-if="row.state=='warning'" status="intermediary" :pulse="true"></status-indicator>
                </template>
                <template v-slot:buttons="{row}">
                  <div class="buttons">
                  <button class="button is-success is-light" v-on:click="editData(row)">EDIT</button>
                </div>
                </template>

              </table-component>
              <br>
              
              
            </div>
        </div>
        <div class="column is-6">
          <l-map ref="myMap" style="height: 1000px;width:100%" :zoom="initialZoom" :center="mapCenter">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker v-for="item in markersLatLng" :lat-lng="item['latlng']" :icon="mapIcon[item['state']]"></l-marker>
          </l-map>

            </div>
          </div>
        </div>
</template>

<script>
import moment from "moment";
import { latLng,icon } from "leaflet";
export default  {
  name: 'DeviceBox',
  async mounted() {
    
  },

  computed:{

    deviceTableData() {
      try{
        var tokenData = this.$store.getters.getCurrentUserToken;
        var devicePerms = this.$store.getters.getDevicePerms
        switch(tokenData.username) {
          
          case "admin":
            return this.$store.getters.getDevicesData;

          case "enea":
            var filteredData = this.$store.getters.getDevicesData.filter((item)=>devicePerms["enea"].includes(item.id));
            return filteredData;

          case "tmobile":
            var filteredData = this.$store.getters.getDevicesData.filter((item)=>devicePerms["tmobile"].includes(item.id));
            return filteredData;
        }
      } catch(error) {
        return [];  
      }
    },
    markersLatLng() {
      var tokenData = this.$store.getters.getCurrentUserToken;
      var devicePerms = this.$store.getters.getDevicePerms;
      var markersData = this.$store.getters.getDevicesData.map((item)=>{
                                                                        return {
                                                                          "id":item["id"],
                                                                          "display":item["display"],
                                                                          "lat":item["lat"],
                                                                          "lng":item["lng"],
                                                                          "state":item["state"],
                                                                          "latlng":latLng(item["lat"],item["lng"])}
                                                                      }
      )
      switch(tokenData.username) {
          
          case "admin":
            return markersData;

          case "enea":
            var filteredData = markersData.filter((item)=>devicePerms["enea"].includes(item.id));
            return filteredData;

          case "tmobile":
            var filteredData = markersData.filter((item)=>devicePerms["tmobile"].includes(item.id));
            return filteredData;
      }
      
    },
    initialZoom() {
      return this.$store.getters.getInitialZoom;
    },
    mapCenter() {
      return this.$store.getters.getMapCenter;
    }
    
    
  },
  data() {
    return {
      isLoading:false,
      modalActive:false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom:this.initialZoom,
      currentSeal:{"id":"","name":"","location":""},
      mapIcon:{
        "armed":icon({
          iconUrl: require('./../assets/marker-icon-armed.png'),
          iconSize: [25, 41],
          iconAnchor: [16, 37]
        }),
        "disarmed":icon({
          iconUrl: require('./../assets/marker-icon-disarmed.png'),
          iconSize: [25, 41],
          iconAnchor: [16, 37]
          }),
        "inactive":icon({
          iconUrl: require('./../assets/marker-icon-inactive.png'),
          iconSize: [25, 41],
          iconAnchor: [16, 37]
        }),
        "warning":icon({
          iconUrl: require('./../assets/marker-icon-warning.png'),
          iconSize: [25, 41],
          iconAnchor: [16, 37]
        }),
      },
      center:this.mapCenter,
      deviceTableSettings: {
          data:this.$store.getters.getDeviceData,
          clickable_rows:true,
          initial_sort: 'created',
          columns:[
              { label: "",field:"",slotName:"indicator"},
              { label: "ID", field: "display" },
              { label: "STATE", field: "state",renderFn:(r)=>{
                switch(r.state) {

                  case "inactive":
                  return `<span class="tag is-secondary">inactive</span>`
                  
                  case "armed":
                  return `<span class="tag is-success">armed</span>`

                  case "disarmed":
                  return `<span class="tag is-danger">disarmed</span>`

                  case "warning":
                  return `<span class="tag is-warning">warning</span>`

                  default:
                  return `<span class="tag is-secondary">inactive</span>`

                }
              }},
              { label: "LAST SEEN",field:"timestamp",renderFn:(r)=>{
                  console.log(r.timestamp)
                  return moment(r.timestamp*1000).format("DD-MM-YYYY HH:mm:ss")
              }},
              { label: "", field: "",slotName:"buttons"}
          ]
      }
    }
  },
  methods:{
    rowClicked(row) {
      var markerIndex = this.markersLatLng.map((item)=>item.id).indexOf(row.id);
      if(markerIndex>=0) {
        this.$refs.myMap.mapObject.flyTo([this.markersLatLng[markerIndex]["lat"],this.markersLatLng[markerIndex]["lng"]],15);
      }     
    },
    editData(data) {
      this.currentSeal = data;
      this.currentSeal.name = data.display;
      this.currentSeal.location = data["lat"]+","+data["lng"];
      this.modalActive = true;
    },

    saveCurrentData() {
      var currentDevices = this.$store.getters.getDevicesData;
      var devIndex = currentDevices.map((item)=>item.id).indexOf(this.currentSeal.id);
      if(devIndex>=0) {
        currentDevices[devIndex]["display"] = this.currentSeal.name;
        currentDevices[devIndex]["lat"] = this.currentSeal.location.split(",")[0];
        currentDevices[devIndex]["lng"] = this.currentSeal.location.split(",")[1];
      }
      this.$mqttClient.publish("nbiot-seal/data",JSON.stringify(currentDevices),{retain:true});
      this.modalActive = false;
      this.$notify({
          group: 'default',
          type:"success",
          title: 'Data saved',
          text:"successfuly saved",
          duration:5000
        });
    },

    async closeModal() {
      
    }
  },
  watch:{
    deviceTableData () {
      this.deviceTableSettings.data = this.$store.getters.getJobsData;
    }
  }
}
</script>

<style scoped>
  .home {
    padding: 25px;
  }

  .message {
    margin-top: 50px;
  }


</style>
