import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import configs from '@/globalData';
import { latLng } from "leaflet";
import moment from 'moment';
import dataStore from './data.js'

Vue.use(Vuex)
//const BACKEND_URL = process.env.BACKEND_URL || "http://localhost:8080";

export default new Vuex.Store({
  state: {
    url:configs.url,
    warnHoursDelay:13,
    devicesData:[],
    initialZoom:13,
    mapCenter:dataStore.mapCenter,
    userTokens:dataStore.usersTokens,
    intervalHandler:undefined
  },
  getters: {
    getUserTokens(state) {
      return state.userTokens;
    },
    getCurrentUserToken(state) {
      try {
        var tokenData = JSON.parse(localStorage.getItem('user-token'));
      } catch(error) {
        return {username:"",password:""};
      }
      return tokenData;
    },
    getDevicesData(state) {
      return state.devicesData;
    },
    
    getInitialZoom(state) {
      return state.initialZoom;
    },
    getMapCenter(state) {
      
      return latLng(state.mapCenter["lat"],state.mapCenter["lng"]);
    },
    getDevicePerms(state) {
      return dataStore.devicesPerm;
    }
  },
  mutations: {
    checkDevicesData(state) {
      for(var i=0;i<state.devicesData.length;i++) {
        if(state.devicesData[i].state=="armed") {
          var deviceMomentTime = moment(state.devicesData[i].timestamp*1000);
          var currentMomentTime = moment(new Date());
          var duration = moment.duration(currentMomentTime.diff(deviceMomentTime));
          var hours = duration.asHours();
          if(hours>=state.warnHoursDelay) {
            state.devicesData[i].state = "warning";
            Vue.notify({
              group: 'default',
              type:"warning",
              title: 'Device changed state to '+state.devicesData[i].state,
              text:state.devicesData[i].id,
              duration:5000
            });
          }
        }
      }
    }
  },
  actions: {
    
    
    async addDeviceData({commit,state},deviceData) {
      try {
        var deviceFound = false;
        if(typeof deviceData.display == "undefined") deviceData.display = deviceData.id;
        if(typeof deviceData.lat == "undefined") deviceData.lat = "0.0";
        if(typeof deviceData.lng == "undefined") deviceData.lng = "0.0";
        for(var i=0;i<state.devicesData.length;i++) {
          if(deviceData.id == state.devicesData[i].id) {
            if(state.devicesData[i].timestamp < deviceData.timestamp) {
              state.devicesData[i].state = deviceData.state;
              state.devicesData[i].tick = deviceData.tick;
              state.devicesData[i].loop = deviceData.loop;
              state.devicesData[i].rssi = deviceData.rssi;
              state.devicesData[i].timestamp = deviceData.timestamp;
            }
            if(deviceData.display.length<state.devicesData[i].display.length) {
              state.devicesData[i].display = deviceData.display;
            }
            if(deviceData.lat.length>state.devicesData[i].lat.length) {
              state.devicesData[i].lat = deviceData.lat;
              state.devicesData[i].lng = deviceData.lng;
            }
            deviceFound = true;
            return; 
          }
        }
        if(deviceFound == false) {
          state.devicesData.push(deviceData);
          
        }
      } catch(error) {
        console.log(error);
        Vue.notify({
          group: 'default',
          type:"error",
          title: 'Cannot update device data',
          text:"check server connection and try again",
          duration:5000
        });
      }
    },

    async checkDevices({commit,state}) {
      if(typeof state.intervalHandler == "undefined") {
        state.intervalHandler = setInterval(()=>commit("checkDevicesData"),10000);
        
      } else {
        clearInterval(state.intervalHandler);
        state.intervalHandler = setInterval(()=>commit("checkDevicesData"),10000);
      }
    },
  },
  modules: {
  }
})
