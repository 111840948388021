module.exports = {
      initialZoom:13,
      mapCenter:{
        "lat":52.41026489200068,
        "lng":16.940016712194872
      },
      usersTokens:[
        {
          username:"admin",
          password:"plomba2024$"
        },
        {
          username:"tmobile",
          password:"kolormagenta2024"
        },
        {
          username:"enea",
          password:"licznik2024"
        }
      ],
      devicesPerm:{
        "tmobile":["008B0084513050132030394B","008B009A513050132030394B"],
        "enea":["0053007F513050012030394B"]

      }
}