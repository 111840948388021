import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import init_custom_comps from '@/vue-custom-components/imports.js' 
import './platform.scss';
import Notifications from 'vue-notification';
import configs from "./globalData.js";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import moment from 'moment';
import StatusIndicator from 'vue-status-indicator';
Vue.use(StatusIndicator);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


Vue.config.productionTip = false
init_custom_comps(Vue)

Vue.use(Notifications);

Vue.prototype.$url = configs.url;
Vue.prototype.$mqttConnectionString = configs.mqttConnectionString; 






function convertStateData(state) {
  var parsedState = state.toLowerCase();
  switch(parsedState) {
    case "nb_seal_state_inactive":
    return "inactive";

    case "nb_seal_state_armed":
    return "armed";

    case "nb_seal_state_tampered":
    return "disarmed";
  }
}

const mqttClient  = mqtt.connect(Vue.prototype.$mqttConnectionString);
mqttClient.on('connect', function () {
  console.log("CONNECTED");
  mqttClient.subscribe("NARROWBAND/#");
  mqttClient.subscribe("nbiot-seal/#");
  store.dispatch("checkDevices");
  
})

mqttClient.on('message', async function (topic, message) {
  
  if(topic.includes("NARROWBAND")) {
    var messageString = new TextDecoder().decode(message);
    var mqttData = JSON.parse(messageString);
    var devID = topic.split("/")[1]
    var deviceTimestamp = moment(mqttData["Data_Time"], "YY/MM/DD,hh:mm:ssZ").add(8, 'hours');
    var deviceData = {}
    deviceData["id"] = devID; 
    deviceData["state"] = convertStateData(mqttData["State"]);
    deviceData["tick"] = mqttData["Tick"];
    deviceData["loop"] = mqttData["Loop"];
    deviceData["rssi"] = mqttData["RSSI"];
    deviceData["timestamp"] = deviceTimestamp.unix();
    store.dispatch("addDeviceData",deviceData);
  }
  if(topic.includes("nbiot-seal")) {
    var msgString = new TextDecoder().decode(message);
    var pageData = JSON.parse(msgString);
    for(var i=0;i<pageData.length;i++) {
      store.dispatch("addDeviceData",pageData[i]);
    }
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$mqttClient = mqttClient;
