<template>
  <div class="home">
    <div class="row">
      <div class="column">
        <LoginBox></LoginBox>
      </div>
    </div>
  </div>
</template>

<script>

import LoginBox from "@/components/LoginBox.vue"

export default {
  name: 'LoginView',
  components:{
    LoginBox
  }

}
</script>
<style>


</style>