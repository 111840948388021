<template>
  <div id="app">
    <notifications group="default" position="top right" width="500"/>
      <nav class="navbar custom-navbar-class"  role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="#">
            <img src="@/assets/logo-sealv3.png">
          </a>
          <div class="navbar-item">
            <div class="buttons">
              
            </div>
          </div>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
            
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a v-if="this.currentRouteName!=='login'" v-on:click="logout()">
                  <span class="button is-info">
                    LOGOUT
                  </span>
                </a>
            </div>
          </div>
        </div>
      </nav>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  
  async mounted() {
    document.title = "SMART PLOMBA";
    console.log(this.currentRouteName);
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    
  },
  methods:{
    logout() {
      localStorage.removeItem('user-token');
      this.$router.push('/login')
    }
  },
  data() {
    return {
      url: this.$url,
      mqttUrl:this.$mqttConnectionString
    }
  }
}
</script>
<style>

html {
  background-color: #eff0eb;
}


</style>
